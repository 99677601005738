import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import {DishesListPage, DishCategoriesListPage} from "../Listing/Listing"
import {DishEditor, DishCategoryEditor} from "./FoodPairingEditors"

const FoodPairingRoutes: FC = () => (
    <Routes>
        <Route path="/dishes" element={<DishesListPage/>}/>
        <Route path="/dishes/:id" element={<DishEditor/>}/>
        <Route path="/dish-categories" element={<DishCategoriesListPage/>}/>
        <Route path="/dish-categories/:id" element={<DishCategoryEditor/>}/>
    </Routes>
)

export default FoodPairingRoutes
