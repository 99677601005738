import React, {FC} from "react"
import {EditorListItemList, EditorListTable} from "../_components/Editor/EditorList"
import {
    TypeAppellation,
    TypeArticle,
    TypeCategory,
    TypeCountry,
    TypeDiscoveryCase,
    TypeDish, TypeDishCategory,
    TypeGrape,
    TypeLanguage,
    TypeManufacturer,
    TypePaymentMethod,
    TypePropertyValue,
    TypeThing,
    TypeWine,
    TypeWineList,
} from "../_types/types"
import {Link} from "react-router-dom"
import {encodeBase64} from "../_helpers/encoding"
import {localizedToString} from "../_helpers/localiztion"

interface ListItemProps {
    item: TypeThing
    name: string
    path: string
}

const ListItem: FC<ListItemProps> = ({item, name, path}) => (
    <li className="nav-item">
        <Link to={`${path}/${encodeBase64(item["@id"])}`}>{name}</Link>
    </li>
)

export const LanguageListPage: FC = () => (
    <EditorListItemList<TypeLanguage>
        filterBy={"name"}
        singular={"Language"}
        plural={"Languages"}
        endpoint={"/languages"}
        getter={item => <ListItem key={item["@id"]} name={item.name} item={item} path={"/localization/languages"}/>}
        path={"/localization/languages"}
    />
)

export const GrapeListPage: FC = () => (
    <EditorListItemList<TypeGrape>
        filterBy={"name"}
        singular={"Grape"}
        plural={"Grapes"}
        endpoint={"/grapes"}
        getter={item => <ListItem key={item["@id"]} name={item.name} item={item} path={"/product/grapes"}/>}
        path={"/product/grapes"}
    />
)

export const CategoryListPage: FC = () => (
    <EditorListItemList<TypeCategory>
        filterBy={"name.@value"}
        singular={"Category"}
        plural={"Categories"}
        endpoint={"/categories"}
        getter={item => <ListItem
            key={item["@id"]}
            name={item.name?.[0]?.["@value"] || item.identifier}
            item={item}
            path={"/product/categories"}
        />}
        path={"/product/categories"}
    />
)

export const WikiCategoryListPage: FC = () => (
    <EditorListItemList<TypeCategory>
        filterBy={"name.@value"}
        singular={"Wiki Category"}
        plural={"Wiki Categories"}
        endpoint={"/wiki-categories"}
        getter={item => <ListItem
            key={item["@id"]}
            name={item.name?.[0]?.["@value"] || item.identifier}
            item={item}
            path={"/content/wiki-categories"}
        />}
        path={"/content/wiki-categories"}
    />
)

export const AgricultureListPage: FC = () => (
    <EditorListItemList<TypePropertyValue>
        limit={50}
        singular={"Agriculture"}
        plural={"Agriculture"}
        endpoint={"/agriculture"}
        getter={item => <ListItem key={item["@id"]} name={localizedToString(item.value)} item={item}
                                  path={"/product/agriculture"}/>}
        path={"/product/agriculture"}
    />
)

export const BottleCapListPage: FC = () => (
    <EditorListItemList<TypePropertyValue>
        limit={50}
        singular={"Bottle Closure"}
        plural={"Bottle Closures"}
        endpoint={"/bottle-caps"}
        getter={item => <ListItem key={item["@id"]} name={localizedToString(item.value)} item={item}
                                  path={"/product/bottle-caps"}/>}
        path={"/product/bottle-caps"}
    />
)

export const AgingListPage: FC = () => (
    <EditorListItemList<TypePropertyValue>
        limit={50}
        singular={"Ageing"}
        plural={"Ageings"}
        endpoint={"/agings"}
        getter={item => <ListItem key={item["@id"]} name={localizedToString(item.value)} item={item}
                                  path={"/product/agings"}/>}
        path={"/product/agings"}
    />
)

export const CountryListPage: FC = () => (
    <EditorListItemList<TypeCountry>
        filterBy={"name.@value"}
        singular={"Country"}
        sortBy={"alternateName"}
        plural={"Countries"}
        endpoint={"/countries"}
        getter={item => (
            <ListItem
                key={item["@id"]}
                name={`${localizedToString(item.name)}
                 (${item.alternateName})`}
                item={item}
                path={"/product/countries"}
            />
        )}
        path={"/product/countries"}
    />
)

export const AppellationListPage: FC = () => (
    <EditorListItemList<TypeAppellation>
        filterBy={"name"}
        singular={"Appellation"}
        plural={"Appellations"}
        endpoint={"/appellations"}
        getter={item => <ListItem key={item["@id"]} name={item.name} item={item} path={"/product/appellations"}/>}
        path={"/product/appellations"}
    />
)

export const ManufacturerListPage: FC = () => (
    <EditorListItemList<TypeManufacturer>
        filterBy={"name"}
        singular={"Vigneron"}
        plural={"Vignerons"}
        endpoint={"/manufacturers"}
        getter={item => <ListItem key={item["@id"]} name={item.name} item={item} path={"/product/manufacturers"}/>}
        path={"/product/manufacturers"}
    />
)

export const WineListPage: FC = () => (
    <EditorListTable<TypeWine>
        filterBy={"name"}
        sortBy={"isArchived,name"}
        singular={"Wine"}
        plural={"Wines"}
        endpoint={"/wines"}
        getter={item => (
            <tr key={item["@id"]}>
                <td className="text-center hide-md">
                    <img alt={""} src={item.image?.thumbnail.contentUrl} height={80}/>
                </td>
                <td className={item.isArchived ? "text-cross" : ""}>{item.name}</td>
                <td className="hide-md">{item.vintage}</td>
                <td className="hide-md">{item.offers.integerPrice / 100}</td>
                <td className="hide-md">{item.offers.availability}</td>
                <td><Link to={`/product/wines/${encodeBase64(item["@id"])}`}>View</Link></td>
                <td><Link to={`/product/wines/create?copy=${encodeBase64(item["@id"])}`}>Clone</Link></td>
            </tr>
        )}
        path={"/product/wines"}
        header={(
            <tr>
                <th className="hide-md"/>
                <th>Name</th>
                <th className="hide-md">Vintage</th>
                <th className="hide-md">Price (EUR)</th>
                <th className="hide-md">Availability</th>
                <th/>
                <th/>
            </tr>
        )}
    />
)

export const DiscoveryCaseListPage: FC = () => (
    <EditorListTable<TypeDiscoveryCase>
        filterBy={"name.@value"}
        sortBy={"isArchived,name.@value"}
        singular={"Discovery Case"}
        plural={"Discovery Cases"}
        endpoint={"/discovery-cases"}
        limit={10}
        getter={item => (
            <tr key={item["@id"]}>
                <td className={item.isArchived ? "text-cross" : ""}>{localizedToString(item.name)}</td>
                <td className="hide-md">{item.wines.length}</td>
                <td className="hide-md">{item.offers.integerPrice / 100}</td>
                <td className="hide-md">{item.offers.availability}</td>
                <td><Link to={`/product/discovery-cases/${encodeBase64(item["@id"])}`}>View</Link></td>
            </tr>
        )}
        path={"/product/discovery-cases"}
        header={(
            <tr>
                <th>Name</th>
                <th className="hide-md">Bottles</th>
                <th className="hide-md">Price (EUR)</th>
                <th className="hide-md">Availability</th>
                <th/>
            </tr>
        )}
    />
)

export const ArticlesListPage: FC = () => (
    <EditorListItemList<TypeArticle>
        filterBy={"name"}
        singular={"Article"}
        plural={"Articles"}
        endpoint={"/articles"}
        getter={item => <ListItem key={item["@id"]} name={item.name} item={item} path={"/content/articles"}/>}
        path={"/content/articles"}
    />
)

export const WikiArticlesListPage: FC = () => (
    <EditorListItemList<TypeArticle>
        filterBy={"name"}
        singular={"Wiki Article"}
        plural={"Wiki Articles"}
        endpoint={"/wiki-articles"}
        getter={item => <ListItem key={item["@id"]} name={item.name} item={item} path={"/content/wiki-articles"}/>}
        path={"/content/wiki-articles"}
    />
)

export const WineListsListPage: FC = () => (
    <EditorListItemList<TypeWineList>
        filterBy={"name.@value"}
        singular={"Wine List"}
        plural={"Wine Lists"}
        endpoint={"/wine-lists"}
        getter={item => <ListItem key={item["@id"]} name={localizedToString(item.name)} item={item}
                                  path={"/content/wine-lists"}/>}
        path={"/content/wine-lists"}
    />
)

export const PaymentMethodsListPage: FC = () => (
    <EditorListItemList<TypePaymentMethod>
        filterBy={"name"}
        singular={"Payment Method"}
        plural={"Payment Methods"}
        endpoint={"/payment-methods"}
        getter={item => <ListItem key={item["@id"]} name={item.name} item={item}
                                  path={"/transactional/payment-methods"}/>}
        path={"/transactional/payment-methods"}
    />
)

export const ShippingCostsListPage: FC = () => (
    <EditorListItemList<TypePaymentMethod>
        filterBy={"name"}
        singular={"Shipping Cost"}
        plural={"Shipping Costs"}
        endpoint={"/shipping-costs"}
        getter={item => <ListItem key={item["@id"]} name={item.name} item={item}
                                  path={"/transactional/shipping-costs"}/>}
        path={"/transactional/shipping-costs"}
    />
)

export const DishesListPage: FC = () => (
    <EditorListItemList<TypeDish>
        filterBy={"name.@value"}
        singular={"Dish"}
        plural={"Dishes"}
        endpoint={"/food-match-dishes"}
        getter={item => <ListItem key={item["@id"]} name={localizedToString(item.name)} item={item}
                                  path={"/food-pairing/dishes"}/>}
        path={"/food-pairing/dishes"}
    />
)

export const DishCategoriesListPage: FC = () => (
    <EditorListItemList<TypeDishCategory>
        filterBy={"name.@value"}
        singular={"Dish Category"}
        plural={"Dish Categories"}
        endpoint={"/food-match-dish-categories"}
        getter={item => <ListItem key={item["@id"]} name={localizedToString(item.name)} item={item}
                                  path={"/food-pairing/dish-categories"}/>}
        path={"/food-pairing/dish-categories"}
    />
)
